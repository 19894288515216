
















































import { defineComponent, PropType } from '@nuxtjs/composition-api';

import { MODAL_NAMES } from '~/components/common/SimpleModal/constants';
import { useUiState } from '~/composables';

import { TYPE_LABELS } from './config';

export default defineComponent({
  name: 'ManufacturerHomeLink',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    inkTypes: {
      type: [Array, null] as PropType<string[]>,
      default: () => ([]),
    },
  },
  setup(props) {
    const { toggleModal } = useUiState();

    const openModal = ($event) => {
      if ($event.view.innerWidth > 1024 || props.inkTypes.length <= 1) {
        return;
      }

      toggleModal({
        identifier: MODAL_NAMES.brandLink,
        props: {
          brandLink: {
            inkTypes: props.inkTypes,
            name: props.name,
            src: props.src,
            alt: props.alt,
            typeLabels: TYPE_LABELS,
          },
        },
      });
    };

    return {
      TYPE_LABELS,
      openModal,
    };
  },
});
